import { Fragment, createContext, h } from 'preact';
import { useContext, useEffect, useMemo, useRef, useState } from 'preact/hooks';
import { ArchiveItemInfo, Client } from 'src/common/types';
import { RemixIcon } from './RemixIcon';
import { ChecksContext } from '../subroutes/guest/editClient';
import { Children, ReactNode, flushSync } from 'preact/compat';
import download from 'downloadjs';
import { GlobalState } from '..';



export function FancyProgress(props: {
    steps: string[],
    step: number,
    onClickStep?: (v: number) => any
}) {
    
    return <div className='d-grid user-select-none'
        style={{
            gridTemplateColumns: `repeat(${props.steps.length}, 1fr)`,
            gridTemplateRows: `min-content min-content`,
            gap: '0',
            justifyContent: 'center',
            justifyItems: 'center',
            alignItems: 'center',
            alignContent: 'center'
        }}
        >
        {props.steps.map((step, n) =>
            <div className={`lisu-progress-step-line ${n == props.steps.length-1 ? '' : (n < props.step ? 'bg-primary' : 'bg-secondary')}`}>
            </div>
        )}
        {props.steps.map((step, n) =>
            <div className={`lisu-progress-step border border-2 ${n < props.step ? 'bg-white border-primary text-primary' : (n == props.step ? 'text-white border-primary bg-primary' : 'bg-white text-secondary border-secondary')}`}
                onClick={e => {
                    e.preventDefault();
                    props.onClickStep && props.onClickStep(n);
                }}
                >
                {n+1}
            </div>
        )}
        {props.steps.map((step, n) =>
            <div className={`lisu-progress-step-label ${n == props.step ? 'text-primary fw-bold' : 'text-secondary'}`}
                onClick={e => {
                    e.preventDefault();
                    props.onClickStep && props.onClickStep(n);
                }}
                >
                {step}
            </div>
        )}
    </div>
    
}

export function SelectInput(props: {
    name: string,
    label?: any,
    value?: string,
    onChange?: (val: string, name: string) => any,
    validate?: (val: string) => string | null,
    isRequired?: boolean,
    options: [string, string][]
}) {
    
    const [hasInput, setHasInput] = useState<boolean>(false);
    const myId = useMemo(() => `inp${Date.now()}${Math.floor(Math.random()*10000)}${Math.floor(Math.random()*10000)}${Math.floor(Math.random()*10000)}`, []);
    
    const checksList = useContext(ChecksContext);
    const labelRef = useRef<HTMLLabelElement>(null);
    
    const validationResult = useMemo(() => !hasInput
        ? null
        : (props.validate
            ? props.validate(props.value ?? (''))
            : null) || (props.isRequired && (props.value ?? '').trim() == '' ? 'Anna kelvollinen arvo' : null),
        [props.value, hasInput]);
    
    useEffect(() => {
        
        if (!checksList) return;
        
        function onCheckValidation() {
            
            const validationResultAlways = (props.validate
                    ? props.validate(props.value ?? (''))
                    : null) || (props.isRequired && (props.value ?? '').trim() == '' ? 'Anna kelvollinen arvo' : null);
            
            setHasInput(true);
            
            return validationResultAlways != null
                ? (labelRef.current ? labelRef.current.textContent : `${props.name}`)
                : null;
                
        }
            
        checksList[props.name] = onCheckValidation;
        
        return () => {
            delete checksList[props.name];
        }
        
    }, [props.value]);
    
    return <div className='mb-3'>
        
        <label for={myId} ref={labelRef}>
            {props.label}
            {props.isRequired && <span className='text-danger ms-1' style={{ fontSize: '12px' }}><RemixIcon icon='ri-asterisk'/></span>}
        </label>
                
        <select 
            id={myId}
            type='text'
            onInput={e => {
                if (!hasInput) setHasInput(true);
                props.onChange && props.onChange(e.currentTarget.value, props.name);
            }}
            value={props.value ?? ''}
            className={[
                'form-select',
                validationResult ? 'is-invalid' : ''
            ].join(' ')}
            >
            <option value={''}>-- Valitse --</option>
            {props.options.map(opt =>
                <option key={opt[0]} value={opt[0]}>{opt[1]}</option>
            )}
        </select>
        
        {validationResult
            ? <div className='invalid-feedback'>
                <RemixIcon icon='ri-alert-fill' className='me-1'/>
                {validationResult}
            </div>
            : ''}
        
    </div>
    
}

export function ValidatedInput(props: {
    name: string,
    label?: any,
    value?: string,
    onChange?: (val: string, name: string) => any,
    validate?: (val: string) => string | null,
    isRequired?: boolean,
    isMultiline?: boolean,
    rows?: number,
    helpContent?: any
}) {
    
    const checksList = useContext(ChecksContext);
    const labelRef = useRef<HTMLLabelElement>(null);
    
    const [hasInput, setHasInput] = useState<boolean>(false);
    const myId = useMemo(() => `${Date.now()}${Math.floor(Math.random()*10000)}${Math.floor(Math.random()*10000)}${Math.floor(Math.random()*10000)}`, []);
    
    const [isHelpOpen, setHelpOpen] = useState<boolean>(false);
    
    const validationResult = !hasInput
        ? null
        : (props.validate
            ? props.validate(props.value ?? (''))
            : null) || (props.isRequired && (props.value ?? '').trim() == '' ? 'Anna kelvollinen arvo' : null);
    
    useEffect(() => {
        
        if (!checksList) return;
        
        function onCheckValidation() {
            
            const validationResultAlways = (props.validate
                    ? props.validate(props.value ?? (''))
                    : null) || (props.isRequired && (props.value ?? '').trim() == '' ? 'Anna kelvollinen arvo' : null);
            
            setHasInput(true);
            
            return validationResultAlways != null
                ? (labelRef.current ? labelRef.current.textContent : `${props.name}`)
                : null;
                
        }
            
        checksList[props.name] = onCheckValidation;
        
        return () => {
            delete checksList[props.name];
        }
        
    }, [props.value]);
    
    
    return <div className='mb-3'>
        
        <label for={myId} className='d-flex justify-content-between' ref={labelRef}>
            <div>
                {props.label}
                {props.isRequired && <span className='text-danger ms-1' style={{ fontSize: '12px' }}><RemixIcon icon='ri-asterisk'/></span>}
            </div>
            <div>
                {props.helpContent
                    && <span className='d-inline-block' style={{ cursor: 'pointer' }}
                        onClick={e => {
                            e.preventDefault();
                            setHelpOpen(op => !op);
                        }}
                        >
                        <RemixIcon icon='ri-question-fill text-primary'/>
                    </span>}
            </div>
        </label>
        
        {props.isMultiline
            ? <textarea
                id={myId}
                onInput={e => {
                    if (!hasInput) setHasInput(true);
                    props.onChange && props.onChange(e.currentTarget.value, props.name);
                }}
                value={props.value ?? ''}
                className={[
                    'form-control',
                    validationResult ? 'is-invalid' : ''
                ].join(' ')}
                rows={props.rows ?? 3}
                {/* @ts-ignore */...{}}
                spellCheck='false'
                />
            : <input
                id={myId}
                type='text'
                onInput={e => {
                    if (!hasInput) setHasInput(true);
                    props.onChange && props.onChange(e.currentTarget.value, props.name);
                }}
                value={props.value ?? ''}
                className={[
                    'form-control',
                    validationResult ? 'is-invalid' : ''
                ].join(' ')}
                {/* @ts-ignore */...{}}
                spellCheck='false'
                />}
        
        {validationResult
            ? <div className='invalid-feedback'>
                <RemixIcon icon='ri-alert-fill' className='me-1'/>
                {validationResult}
            </div>
            : ''}
        
        {props.helpContent && isHelpOpen &&
            <div className='alert alert-primary d-flex align-items-center p-2 mt-2 fade' ref={el => setTimeout(() => el?.classList.add('show'), 10)}>
                <div className='me-2' style={{ fontSize: '24px' }}>
                    <RemixIcon icon='ri-information-line'/>
                </div>
                <div style={{ fontSize: '80%' }}>
                    {props.helpContent}
                </div>
            </div>}
        
    </div>
    
}

export function LoadingSpinner() {
    return <div className='lisu-ui container-fluid m-4 p-4 text-center'>
        <div className='spinner-border text-primary' role='status'></div>
    </div>;
}

export function useQuery<T>(apiEndpoint: string) {
    
    const [reloadCount, setReloadCount] = useState<number>(0);
    const [result, setResult] = useState<T | null>(null);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    
    const reload = useMemo(() => function(){
        setReloadCount(r => r+1);
    }, []);
    
    useEffect(() => {
        setLoading(true);
        getAPI(apiEndpoint)
            .then(apiResult => {
                setResult(apiResult);
                setLoading(false);
            })
            .catch(err => {
                setError(`${err}`);
                setLoading(false);
            })
    }, [apiEndpoint, reloadCount]);
    
    return {
        error,
        isLoading,
        result,
        reload
    };
    
}

export async function postAPI(apiEndpoint: string, postData: any) {
    const x = await fetch(`${apiEndpoint}`, {
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'post',
        body: JSON.stringify(postData)
    });
    if (!x.ok || x.status != 200) {
        let errorText = '';
        try {
            const bodyText = await x.text();
            const bodyJson = JSON.parse(bodyText);
            if (bodyJson && bodyJson.error) {
                errorText = bodyJson.error;
            }
        } catch (err) {}
        throw new Error(`Fetch error: Status ${x.status} / ${x.statusText}\n\n${errorText}`);
    }
    return await x.json();
}

export async function getAPI(apiEndpoint: string) {
    const x = await fetch(`${apiEndpoint}`);
    if (!x.ok || x.status != 200) {
        let errorText = `Fetch error: Status ${x.status} / ${x.statusText}`;
        try {
            const bodyText = await x.text();
            const bodyJson = JSON.parse(bodyText);
            if (bodyText != '') {
                errorText = bodyText;
            }
            if (bodyJson && bodyJson.error) {
                errorText = bodyJson.error;
            }
        } catch (err) {}
        throw new Error(`${errorText}`);
    }
    return await x.json();
}

export async function fetchClientsBulk(clients: string[]) {
    
    const results = await Promise.allSettled(clients.map(clientId => getAPI(`/api/getClient/${encodeURIComponent(clientId ?? '?')}`)) as Promise<Client>[])
    
    const hadErrors = results.filter(res => res.status == 'rejected').map(res => 'reason' in res ? res.reason : '');
    const successResults = results.map(res => res.status == 'fulfilled' ? res.value : null).filter(res => !!res);
    
    if (hadErrors.length > 0) {
        throw new Error(`Errors occurred while fetching data: ${hadErrors.join('\n')}`)
    }
    
    return successResults;
    
}

export async function fetchMapClientsBulk(clients: string[], mapper: (cl: Client) => string) {
    const results = await fetchClientsBulk(clients);
    const noResult = results.filter(cl => !!cl).filter(cl => cl ? (mapper(cl) ?? '').trim() == '' : true) as Client[];
    const hadResult = results.filter(cl => !!cl).map(cl => cl ? (mapper(cl) ?? '') : '').filter(t => t.trim() != '');
    return [noResult, hadResult] as [Client[], string[]];
}

export function validateCompanyId(companyId: string) {

    if (/^[0-9]{6}-[0-9]{1}/.test(companyId)) {
        companyId = '0' + companyId;
    }

    if (!/^[0-9]{7}-[0-9]{1}/.test(companyId)) {
        return false;
    }

    const [id, checksumStr] = companyId.split('-');
    const checksum = parseInt(checksumStr, 10);

    const multipliers = [7, 9, 10, 5, 8, 4, 2];
    let totalCount = 0;

    for (let i = 0; i < multipliers.length; i++) {
        totalCount += multipliers[i] * parseInt(id[i], 10);
    }

    const remainder = totalCount % 11;

    if (remainder === 1) {
        return false;
    }

    if (remainder === 0) {
        return checksum === remainder;
    }

    return checksum === 11 - remainder;
    
}

export const LisuLangContext = createContext({ lang: 'fi', setLang: (l: string) => { return; } });

export function L(props: { en: any, fi?: any, children?: any }) {
    
    const {lang: currentLang, setLang} = useContext(LisuLangContext);
    
    if (!props.fi) {
        props.fi = props.children;
    }
    
    return <Fragment>
        {currentLang == 'en' || currentLang == 'fi'
            ? props[currentLang]
            : (props.fi ?? props.children)}
    </Fragment>;
    
}

export function lang(props: { en: any, fi: any }) {
    
    const currentLang = (window as any).currentLang as string;
    
    return currentLang == 'en' || currentLang == 'fi'
        ? props[currentLang]
        : props.fi;
    
}

export function ArchiveItemList(props: { itemId: string | string[], state: GlobalState }) {   
    
    const { error, result, isLoading, reload } = useQuery<ArchiveItemInfo[]>(`/api/getArchiveItems/${encodeURIComponent(Array.isArray(props.itemId) ? props.itemId.join(',') : props.itemId)}`);
    
    if (error) {
        return <div className='m-3 alert alert-danger'>
            {error}
        </div>;
    }
    
    if (isLoading || !result) {
        return <div className='m-3 lisu-card'>
            <LoadingSpinner/>
        </div>;
    }
    
    return <div className='m-3 lisu-card'>
        
        <h3><RemixIcon icon='ri-archive-drawer-line'/> Arkistoidut versiot</h3>
        
        <table className='table table-sm table-hover'>
            <thead>
                <tr>
                    <th>Tyyppi</th>
                    <th>Versio</th>
                    <th>Tekijä</th>
                    <th>Aikaleima</th>
                    <th>Tyyppi</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {result.length == 0 && <tr><td colSpan={5} className='text-center'><i>Ei vielä yhtään arkistoitua versiota!</i></td></tr>}
                {result.map((row, i) =>
                    <tr key={row.versionId ?? i}>
                        <td className='align-middle'>{(row.itemId ?? '').split('_')[0]}</td>
                        <td className='align-middle'><span className='mono'>{row.versionId}</span></td>
                        <td className='align-middle'>{row.authorId}</td>
                        <td className='align-middle'>{new Date(row.timestamp ?? 0).toLocaleString('fi')}</td>
                        <td className='align-middle'>{row.mimeType}</td>
                        <td className='text-end align-middle'>
                            <a className='btn btn-sm btn-outline-secondary me-2'
                                target='_blank'
                                href={`/api/getArchiveItem/${encodeURIComponent(row.itemId ?? '-')}/${encodeURIComponent(row.versionId ?? '-')}`}
                                >
                                <RemixIcon icon='ri-eye-line'/>
                            </a>
                            <button className='btn btn-sm btn-primary me-2' type='button'
                                onClick={e => {
                                    e.preventDefault();
                                    if (e.shiftKey) {
                                        window.open(`/api/getArchiveItem/${encodeURIComponent(row.itemId ?? '-')}/${encodeURIComponent(row.versionId ?? '-')}`, '_blank');
                                        return;
                                    }
                                    fetch(`/api/getArchiveItem/${encodeURIComponent(row.itemId ?? '-')}/${encodeURIComponent(row.versionId ?? '-')}`)
                                        .then(async x => {
                                            if (x.headers.get('content-type') != 'application/pdf') {
                                                throw new Error(`Failed: ${await x.text()}`);
                                            }
                                            return x.blob();
                                        })
                                        .then(pdfBlob => {
                                            download(pdfBlob, `arkisto_${row.itemId}_${row.versionId}.pdf`, row.mimeType ?? 'application/pdf');
                                        })
                                        .catch(err => {
                                            props.state.createToast(<span className='text-danger'>Latauksen haku epäonnistui<br/><br/>{err}</span>, 5000);
                                        })
                                }}
                                >
                                <RemixIcon icon='ri-download-line'/>
                            </button>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
        
    </div>;
    
}

export function getQuarter(d?: Date) {
    if (!d) {
        d = new Date();
    }
    return Math.floor((d.getMonth() + 3) / 3);
}

export function getYearAsQuartersFromNow() {
    
    const d = new Date();
    const dYear = new Date(new Date().setFullYear(d.getFullYear()+1));
    
    const qNow = getQuarter();
    const qNowText = `Q${qNow}/${d.getFullYear()}`;
    
    const qYear = (qNow + 3) % 4;
    const qYearText = `Q${qYear}/${d.getFullYear()+1}`;
    
    return `${qNowText}–${qYearText} (${d.toLocaleDateString('fi')}–${getQuarterBounds(qYear)[1]}${d.getFullYear()+1})`;
    
}

const QUARTERS = [
    ['1.1.', '31.3.'],
    ['1.4.', '30.6.'],
    ['1.7.', '30.9.'],
    ['1.10.', '31.12.']
];
export function getQuarterBounds(q: number) {
    if (q < 0) q = 4+q;
    return QUARTERS[q % QUARTERS.length];
}

