import { h, render } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { io } from 'socket.io-client';
import EventEmitter from 'eventemitter3';
import { LisuApp } from './lisuapp';
import { RemixIcon } from './utils/RemixIcon';
import { Link } from 'react-router-dom';
import { Client } from 'src/common/types';

const base = document.querySelector('#base');
if (!base) throw new Error(`No base?!`);

const globalState = {
    events: new EventEmitter(),
    selectQuote: (currentSelection?: string) => new Promise<string[]>((resolve, reject) => {
        globalState.events.emit(AppEvents.SELECT_QUOTE, (items: string[] | null) => items ? resolve(items) : reject(), currentSelection);
    }),
    selectClient: (currentSelection?: string) => new Promise<string[]>((resolve, reject) => {
        globalState.events.emit(AppEvents.SELECT_CLIENT, (items: string[] | null) => items ? resolve(items) : reject(), currentSelection);
    }),
    selectLicenses: (currentSelection?: string[]) => new Promise<string[]>((resolve, reject) => {
        globalState.events.emit(AppEvents.SELECT_LICENSES, (items: string[] | null) => items ? resolve(items) : reject(), currentSelection);
    }),
    createToast: (content: any, timeToLive?: number, onClose?: () => any) => {
        globalState.events.emit(AppEvents.TOAST_ADD, content, timeToLive, onClose);
    },
    createListFailedClientsToast: (listClients: (Client | null)[]) => {
        globalState.createToast(
            <div className='text-danger'>
                <h6>Seuraavien asiakkaiden tietoja ei voitu koota:</h6>
                <div style={{ maxHeight: '300px', overflowY: 'scroll' }}>
                    {listClients.map(cli =>
                        <p className='mb-0'>
                            <RemixIcon icon='ri-alert-fill' className='text-danger me-1'/>
                            <Link to={`/app/admin/clients/${cli?.clientId ?? '?'}`}>
                                {cli?.clientLegalName ?? '?'}
                            </Link>
                        </p>
                    )}
                </div>
            </div>
        )
    }
};

export enum AppEvents {
    TOAST_ADD = 'toastAdd',
    LISTS_UPDATE = 'listsUpdate',
    SELECT_QUOTE = 'selectQuote',
    SELECT_CLIENT = 'selectClient',
    SELECT_LICENSES = 'selectLicenses',
}

export type GlobalState = typeof globalState;
(window as any).globalState = globalState;

render(<LisuApp state={globalState}/>, base);
