import { Fragment, createContext, h } from 'preact';
import { useContext, useRef, useState } from 'preact/hooks';
import { useNavigate, useParams } from 'react-router';
import { RemixIcon } from '../../utils/RemixIcon';
import { FancyProgress, L, LisuLangContext, LoadingSpinner, SelectInput, ValidatedInput, lang, postAPI, useQuery, validateCompanyId } from '../../utils/utils';
import ReCAPTCHA from 'react-google-recaptcha';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { Client, GuestEditableClient } from '../../../common/types';
import emailValidator from 'email-validator';

export function GuestEditClient(props: {  }) {
    
    const navigate = useNavigate();
    const urlParams = useParams();
    
    const { error, result: isVerifiedResp, isLoading } = useQuery<{ isVerified: boolean }>(`/api/guest/isVerified`);
    
    useEffect(() => {
        document.body.style.overflowY = 'scroll';
    }, []);
    
    if (isLoading) {
        return <div className='text-center p-4'>
            <h3><L fi='Hetkinen...' en='Just a sec...'/></h3>
            <LoadingSpinner/>
        </div>
    }
    
    if (error || !isVerifiedResp) {
        return <div className='text-center p-4'>
            <GuestError>
                <L fi='Vieraskirjautumisen tarkistuksessa tapahtui virhe. Yritä myöhemmin uudelleen.'
                    en='An error occurred. Please try again later.'/>
            </GuestError>
        </div>
    }
    
    const { editKey } = urlParams;
    const {lang: currentLang, setLang} = useContext(LisuLangContext);
    const langGetter = lang;
    
    return <Fragment>
        <div className='lisu-ui-wrapper'>
            <nav className='navbar navbar-expand-lg navbar-dark bg-dark text-light sticky-top'>
                <a className='navbar-brand ms-3 lisu-logo-small' href='#'>Lisu<sup>5</sup></a>
                <button className='navbar-toggler'
                    type='button'
                    data-toggle='collapse'
                    data-target='#navbarDropdown'
                    aria-controls='navbarDropdown'
                    aria-expanded='false'
                    >
                    <span className='navbar-toggler-icon'></span>
                </button>
                <div className='collapse navbar-collapse' id='navbarDropdown'>
                    <ul className='navbar-nav'>
                    </ul>
                </div>
                <div className='d-inline-block me-3'>
                    <ul className='navbar-nav'>
                        <li className='navbar-item'>
                            <a className='nav-link'
                                href='#'
                                onClick={e => {
                                    e.preventDefault();
                                    setLang('fi');
                                }}
                                >
                                FI
                            </a>
                        </li>
                        <li className='navbar-item'>
                            <a className='nav-link'
                                href='#'
                                onClick={e => {
                                    e.preventDefault();
                                    setLang('en');
                                }}
                                >
                                EN
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
            <div className='lisu-ui container gx-0'>
                
                <div className='row gx-0 justify-content-center mx-auto mb-4' style={{ maxWidth: '800px' }}>
                    
                    <div className='col-12 col-md-10 mt-1'>
                        
                    {isVerifiedResp.isVerified
                        ? <Fragment>
                            
                            <ClientEditForm
                                editKey={editKey}
                                key={editKey}
                                />
                            
                        </Fragment>
                        : <div className='lisu-card m-4'>
                            <ClientVerifyForm
                                editKey={editKey}
                                key={editKey}
                                />
                        </div>}
                        
                    </div>
                    
                </div>
                
            </div>
        </div>
    </Fragment>
    
}

export function GuestError(props: { children: any }) {
    return <div className='alert alert-danger p-0 mt-4'>
        <div className='d-flex align-items-center'>
            <div style={{ fontSize: '48px' }} className='px-3 py-2 text-danger'><RemixIcon icon='ri-alert-fill'/></div>
            <div className='p-2 ps-0'>
                {props.children}
            </div>
        </div>
    </div>;
}

export const ChecksContext = createContext<Record<string, () => string | null>>({});

export function ClientEditForm(props: { editKey?: string }) {
    
    const { editKey } = props;
    
    if (!editKey) {
        return <GuestError>
            <L fi='Virheellinen muokkausavain. Varmista, että avaamasi linkki on oikein.'
                    en='Invalid editing key. Ensure that the link you opened is valid.'/>
        </GuestError>
    }
    
    const { error, result, isLoading } = useQuery<{ data: GuestEditableClient, error?: string }>(`/api/guest/getEditClientDetails/${editKey}`);
    const [isLoadedInitial, setLoadedInitial] = useState<boolean>(false);
    const [isSaved, setSaved] = useState<boolean>(false);
    const [values, setValues] = useState<Record<string, string>>({});
    
    const onChange = useCallback((value: string, name: string) => {
        setValues(v => ({ ...v, [name]: value }));
    }, []);
    
    useEffect(() => {
        
        if (!result || isLoading) return;
        if (isLoadedInitial) return;
        
        console.log(result);
        
        setValues({ ...(result.data as any) });
        setLoadedInitial(true);
        
    }, [result, isLoadedInitial, isLoading]);
    
    const getInputOpts = (name: string) => ({ name, value: values[name] ?? '', onChange });
    
    const [currentPart, setCurrentPart] = useState<number>(0);
    const checksRef = useRef<Record<string, () => string | null>>({});
    
    if (isLoading) {
        return <div className='text-center'>
            <h3><L fi='Hetkinen...' en='Just a sec...'/></h3>
            <LoadingSpinner/>
        </div>
    }
    
    if (error && error == 'Error: 404 Client edit link not found') {
        return <GuestError>
            <strong><L fi='Tämä linkki ei kelpaa.' en='This link is not valid.'/></strong> {' '}
            <L fi='Linkkiä voi käyttää vain kerran, rajatun ajan sisällä. Jos arvelet, että tämä on virhe, ota yhteys asiakaspalveluun.'
                en='Please ensure that the link you opened is valid. You may only use the link once, during a restricted time. If you think this is an error, please contact support.'/>
        </GuestError>
    }
    
    if (error || !result) {
        return <GuestError>
            <strong><L fi='Asiakastietojen hakemisessa tapahtui virhe.' en='Unable to fetch client details.'/></strong> {' '}
            <L fi='Varmista, että linkki avattiin täydellisenä, eikä siitä puutu merkkejä. Jos tämä toistuu, ota yhteys asiakaspalveluun.'
                en='Please ensure that the link you opened is valid. If this happens again, please contact support.'/>
            <pre className='my-3'>{error}</pre>
        </GuestError>
    }
    
    useEffect(() => {
        
        function onBeforeUnload(e: BeforeUnloadEvent) {
            if (!isSaved) {
                //e.preventDefault();
                //return false;
            }
        }
        
        window.addEventListener('beforeunload', onBeforeUnload);
        
        return () => {
            window.removeEventListener('beforeunload', onBeforeUnload);
        }
        
    }, [isSaved]);
    
    const parts: string[] = [];
    let p = 0;
    const createPart = (pn: string) => {
        parts.push(pn);
        return p++;
    }
    
    if (isSaved) {
        return <div className='lisu-card mx-4 my-3 text-center'>
            <h3 className='display-3 text-success'>
                <L en='Thanks!'>Kiitos!</L>
            </h3>
            <p className='lead'>
                <strong>
                    <L en='The details were saved and you may close the window.'>Tiedot on nyt talletettu, ja voit sulkea ikkunan.</L>
                </strong>
            </p>
            <p className='lead mb-0'>
                <L en='If any of the details or contact information change, please notify us via e-mail as soon as possible.'>
                    Mikäli asiakastietoihin tai yhteyshenkilön yhteystietoihin tulee muutoksia, ilmoita siitä mahdollisimman pian sähköpostitse.
                </L>
            </p>
        </div>;
    }
    
    const { lang: currentLang } = useContext(LisuLangContext);
    
    return <ChecksContext.Provider value={checksRef.current}>
        
        <div className='lisu-card mx-4 my-3'>
            <FancyProgress
                step={currentPart}
                steps={[
                    lang({ fi: 'Ohjeet', en: 'Start' }),
                    lang({ fi: 'Perustiedot', en: 'Basics' }),
                    lang({ fi: 'Yhteystiedot', en: 'Contact info' }),
                    lang({ fi: 'Laskutus', en: 'Billing' }),
                    lang({ fi: 'Yhteenveto', en: 'Summary' }),
                ]}
                onClickStep={n => setCurrentPart(n)}
                />
        </div>
        
        {currentPart == createPart('Ohjeet') && <Fragment>
            <div className='lisu-card mx-4 my-3'>
                <h3><L fi='Asiakastietojen täydennys' en='Client detail entry'/></h3>
                <p className='mb-0'>
                <L fi={`Tervetuloa L\'Math-lisenssinhallinnan asiakastietojärjestelmään.
                    Avaamallasi linkillä voit täydentää tarvitut asiakas- ja laskutustiedot.
                    Yksi linkki toimii vain kerran – pyydä tarvittaessa uusi linkki asiakaspalvelusta.`}
                    en={`Welcome to the L'Math CRM.
                        Via this link, you can fill in the required client and billing details.
                        The link will only work once – if needed, request a new one from the support.`}/>
                </p>
                <p>
                    <a href='https://lehtodigital.fi/lmath/commercial/gdpr' target='_blank'>
                        &raquo; <L fi={`Tietosuoja`} en={`Privacy notice`}/>
                    </a>
                </p>
                
                <h5><L en='Getting started'>Ohjeita</L></h5>
                <ul className='mb-0'>
                    <li className='mb-2'>
                        <L en='Some of the details may have already been entered based on communications. Check and complete and fill in any missing or incomplete information.'>
                            Osa tiedoista on voitu täyttää jo etukäteen asiakasviestinnän perusteella. Tarkista ja täydennä tietoja tarvittaessa ja lisää puuttuvat tiedot.
                        </L>
                    </li>
                    <li className='mb-2'>
                        <L en='Use the "Previous" and "Next" buttons to move between pages.'>
                            Voit liikkua sivujen välillä alaosan "Edellinen"- ja "Seuraava"-painikkeilla.
                        </L>
                    </li>
                    <li className='mb-2'>
                        <L en={<Fragment>
                            View tips by clicking on the <span className='text-primary'><RemixIcon icon='ri-question-fill'/></span> question mark icon.
                        </Fragment>}>
                            Klikkaamalla kysymysmerkistä <span className='text-primary'><RemixIcon icon='ri-question-fill'/></span> saat esiin kenttää koskevan ohjeen.
                        </L>
                    </li>
                    <li>
                        <L en={<Fragment>
                            Fields marked with <span className='text-danger'><RemixIcon icon='ri-asterisk'/></span> red asterisks are mandatory.
                        </Fragment>}>
                            Tähdellä <span className='text-danger'><RemixIcon icon='ri-asterisk'/></span> merkityt kentät ovat pakollisia.
                        </L>
                    </li>
                </ul>
            </div>
        </Fragment>}
        
        {currentPart == createPart('Perustiedot') && <Fragment>
            <div className='lisu-card mx-4 my-3'>
                <h4><L en='Basics'>Perustiedot</L></h4>
            
                <div className='row'>
                    <div className='col-12 col-md-12'>
                        <ValidatedInput
                            {...getInputOpts('clientLegalName')}
                            label={<Fragment><RemixIcon icon='ri-id-card-line'/> <L en='Legal name of client'>Asiakkaan nimi</L></Fragment>}
                            validate={val => `${val}`.trim() == '' ? lang({ fi: `Anna kelvollinen nimi`, en: `Enter a valid name` }) : null}
                            isRequired={true}
                            helpContent={<L en='The name of the company, organization, city/commune, or individual person'>Yrityksen, kaupungin, organisaation tai yksityishenkilön nimi</L>}
                            />
                    </div>
                    <div className='col-12 col-md-6'>
                        <SelectInput
                            {...getInputOpts('clientLegalType')}
                            label={<Fragment><RemixIcon icon='ri-group-2-line'/> <L en='Client type'>Asiakastyyppi</L></Fragment>}
                            validate={val => `${val}`.trim() == '' ? lang({ fi: `Valitse asiakastyyppi`, en: `Please choose a type` }) : null}
                            isRequired={true}
                            options={[
                                ['commune', lang({ fi: `Kunta tai kaupunki`, en: `Commune or city` })],
                                ['company', lang({ fi: `Yritys`, en: `Company` })],
                                ['org', lang({ fi: `Yhdistys tai säätiö`, en: `Association or foundation` })],
                                ['individual', lang({ fi: `Yksityishenkilö`, en: `Private person` })],
                                ['other', lang({ fi: `Jokin muu`, en: `Other` })]
                            ]}
                            />
                    </div>
                    <div className='col-12 col-md-6'>
                        {values['clientLegalType'] !== 'individual'
                            && <ValidatedInput
                                    {...getInputOpts('clientVATNumber')}
                                    label={<Fragment><RemixIcon icon='ri-price-tag-3-line'/> <L en='Company ID (y-tunnus)'>Y-tunnus</L></Fragment>}
                                    validate={val => `${val}`.trim() == '' || validateCompanyId(val) ? null : lang({ fi: `Anna kelvollinen y-tunnus`, en: `Enter a valid company ID` })}
                                    />}
                    </div>
                </div>
            </div>
        </Fragment>}
        
        {currentPart == createPart('Yhteystiedot') && <Fragment>
            <div className='lisu-card mx-4 my-3'>
                <h4><L en='Contact details'>Yhteystiedot</L></h4>
                
                <h5 className='mt-3'><L en='Contact details'>Yhteyshenkilöiden tiedot</L></h5>
                <div className='row'>
                    <div className='col-12 col-md-6'>
                        <ValidatedInput
                            {...getInputOpts('clientEmail')}
                            label={<Fragment><RemixIcon icon='ri-mail-line'/> <L en='E-mail address'>Sähköpostiosoite</L></Fragment>}
                            validate={val => !emailValidator.validate(val) ? lang({ fi: `Anna kelvollinen sähköpostiosoite`, en: `Enter a valid email` }) : null}
                            isRequired={true}
                            helpContent={<L en='Used when contacting you about customer maintenance, contract matters, and invoicing questions.'>Osoite asiakkuuden ylläpitämistä, sopimusasioita sekä laskutuskysymyksiä varten.</L>}
                            />
                    </div>
                    <div className='col-12 col-md-6'>
                        <ValidatedInput
                            {...getInputOpts('clientTechnicalEmail')}
                            label={<Fragment><RemixIcon icon='ri-mail-settings-line'/> <L en='Technical e-mail'>Tekninen sähköpostiosoite</L></Fragment>}
                            validate={val => !emailValidator.validate(val) ? lang({ fi: `Anna kelvollinen sähköpostiosoite`, en: `Enter a valid email` }) : null}
                            isRequired={true}
                            helpContent={<L en='Used when sending update notifications or technical details.'>Osoite päivityksiä, teknisiä ilmoituksia ja asennustietoja varten.</L>}
                            />
                    </div>
                    <div className='col-12 col-md-6'>
                        <ValidatedInput
                            {...getInputOpts('clientTelephone')}
                            label={<Fragment><RemixIcon icon='ri-phone-line'/> <L en='Phone number'>Puhelinnumero</L></Fragment>}
                            validate={val => `${val}`.trim() != '' && !/^[0-9\+ ]+$/gmi.test(val) ? lang({ fi: `Anna kelvollinen puhelinnumero`, en: `Enter a valid phone number (0-9, space and + allowed)` }) : null}
                            helpContent={<L en='Usually, we only contact you via e-mail, thus the phone number is not mandatory.'>
                                Hoidamme viestinnän pääsääntöisesti sähköpostitse.
                                Puhelinnumero on vapaaehtoinen, ja sitä käytetään yleensä vain, jos emme tavoita asiakasta muutoin.
                                </L>}
                            />
                    </div>
                </div>
                
                <h5 className='mt-3'><L en='Mailing address'>Postiosoite</L></h5>
                <div className='row'>
                    <div className='col-12 col-md-12'>
                        <ValidatedInput
                            {...getInputOpts('clientAddress')}
                            label={<Fragment><RemixIcon icon='ri-route-line'/> <L en='Mailing address'>Postiosoite</L></Fragment>}
                            validate={val => `${val}`.trim() == '' ? lang({ fi: `Anna kelvollinen postiosoite`, en: `Enter a valid address` }) : null}
                            isRequired={true}
                            isMultiline={true}
                            helpContent={<L en='The mailing address is required for invoicing, regardless of how the invoice is sent. If you choose to receive a paper invoice via mail, this address will be used.'>
                                Postiosoite vaaditaan laskutustavasta riippumatta.
                                Mikäli haluatte laskun postitse, se lähetetään tähän osoitteeseen.
                            </L>}
                            />
                    </div>
                    <div className='col-12 col-md-6'>
                        <ValidatedInput
                            {...getInputOpts('clientPostalCode')}
                            label={<Fragment><RemixIcon icon='ri-building-2-line'/> <L en='Postal code'>Postinumero</L></Fragment>}
                            validate={val => !/^[0-9]+$/gmi.test(val) ? lang({ fi: `Anna kelvollinen postinumero`, en: `Enter a valid postal code` }) : null}
                            isRequired={true}
                            />
                    </div>
                    <div className='col-12 col-md-6'>
                        <ValidatedInput
                            {...getInputOpts('clientCity')}
                            label={<Fragment><RemixIcon icon='ri-map-pin-line'/> <L en='City'>Postitoimipaikka</L></Fragment>}
                            validate={val => `${val}`.trim() == '' ? lang({ fi: `Anna kelvollinen arvo`, en: `Enter a valid value` }) : null}
                            isRequired={true}
                            />
                    </div>
                </div>
            </div>
        </Fragment>}
        
        {currentPart == createPart('Laskutus') && <Fragment>
            <div className='lisu-card mx-4 my-3'>
                <h4><L en='Invoicing'>Laskutus</L></h4>
                
                <div className='row'>
                    <div className='col-12 col-md-6'>
                        <SelectInput
                            {...getInputOpts('clientBillingPlan')}
                            label={<Fragment><RemixIcon icon='ri-file-paper-2-line'/> <L en='Billing method'>Laskutustapa</L></Fragment>}
                            validate={val => `${val}`.trim() == '' ? lang({ fi: `Valitse kelvollinen arvo`, en: `Pick a valid value` }) : null}
                            isRequired={true}
                            options={[
                                ['ebill', lang({ fi: `Verkkolasku`, en: `Online billing` })],
                                ['email', lang({ fi: `Lasku sähköpostiin`, en: `Invoice to email` })],
                                ['post', lang({ fi: `Paperilasku postitse (laskutuslisä 5,00 euroa)`, en: `Paper invoice via mail (supplement +5,00 €)` })]
                            ]}
                            />
                    </div>
                    <div className='col-12 col-md-6'>
                        <ValidatedInput
                            {...getInputOpts('clientBillingRef')}
                            label={<Fragment><RemixIcon icon='ri-eye-line'/> <L en='Your reference'>Viitteenne</L></Fragment>}
                            helpContent={<L en='If a reference number should be marked on the invoice (e.g. name of orderer, billing number), enter it here.'>
                                Mikäli laskulle tulee merkitä jokin viite, kuten kustannuspaikka, tilaaja, tai tilausnumero,
                                syötä tieto tähän kenttään.
                            </L>}
                            />
                    </div>
                </div>
            
                {values['clientBillingPlan'] == 'ebill'
                    && <Fragment>
                        <ValidatedInput
                            {...getInputOpts('clientOVT')}
                            label={<Fragment><RemixIcon icon='ri-link-unlink'/> <L en='Online billing number (OVT)'>OVT-tunnus</L></Fragment>}
                            validate={val => `${val}`.trim() == '' ? lang({ fi: `Anna kelvollinen arvo`, en: `Enter a valid value` }) : null}
                            helpContent={<L en={<Fragment>
                                When using online billing, an <a href='https://procountor.fi/taloushallinnon-sanakirja/ovt-tunnus/' target='_blank' rel='nofollow'>OVT number</a> is required.
                            </Fragment>}>
                                E-laskuna lähetettäville laskuille tarvitaan <a href='https://procountor.fi/taloushallinnon-sanakirja/ovt-tunnus/' target='_blank' rel='nofollow'>OVT-tunnus</a>.
                            </L>}
                            />
                    </Fragment>}
            
                {values['clientBillingPlan'] == 'email'
                    && <Fragment>
                        <ValidatedInput
                            {...getInputOpts('clientOVT')}
                            label={<Fragment><RemixIcon icon='ri-link-unlink'/> <L en='E-mail for invoicing'>Laskutuksen sähköpostiosoite</L></Fragment>}
                            validate={val => `${val}`.trim() == '' ? lang({ fi: `Anna kelvollinen arvo`, en: `Enter a valid value` }) : null}
                            />
                    </Fragment>}
            
                {values['clientBillingPlan'] == 'post'
                    && <Fragment>
                        <div className='alert alert-info'>
                            <RemixIcon icon='ri-information-line'/> <L en='The mailing address from an earlier page will be used for invoicing'>Laskutusosoitteena käytetään aiemmin annettua postiosoitetta</L>
                        </div>
                        <div className='alert alert-warning'>
                            <RemixIcon icon='ri-information-line'/> <b><L en='An invoicing supplement of €5.00 will be added to the invoice'>Laskutuslisä on 5,00 €, ja se lisätään laskulle</L></b>
                        </div>
                    </Fragment>}
            </div>
        </Fragment>}
        
        {currentPart == createPart('Yhteenveto') && <Fragment>
            <div className='lisu-card mx-4 my-3'>
                
                <h4><L en='Summary'>Yhteenveto</L></h4>
                
                <div className='alert alert-info'>
                    <L en='Please verify the information below. If necessary, go back to make edits using the "Previous" button. When ready, click on the "Submit details" button.'>
                        Tarkista tiedot alta.
                        Palaa tarvittaessa muokkaamaan tietoja "Edellinen"-painikkeella.
                        Kun tiedot ovat kunnossa, klikkaa "Lähetä tiedot" -painiketta.
                    </L>
                </div>
                
                <div className='mb-3'>
                    <h6 className='mb-1'><L en='Legal name'>Asiakkaan nimi</L></h6>
                    <p className='mb-0'>{values['clientLegalName'] || '-'}</p>
                </div>
                
                <div className='mb-3'>
                    <h6 className='mb-1'><L en='Type'>Asiakastyyppi</L></h6>
                    <p className='mb-0'>{values['clientLegalType'] || '-'}</p>
                </div>
                
                <div className='mb-3'>
                    <h6 className='mb-1'><L en='Company ID (y-tunnus)'>Y-tunnus</L></h6>
                    <p className='mb-0'>{values['clientVATNumber'] || '-'}</p>
                </div>
                
                <div className='mb-3'>
                    <h6 className='mb-1'><L en='Contact email'>Yhteyshenkilön sähköpostiosoite</L></h6>
                    <p className='mb-0'>{values['clientEmail'] || '-'}</p>
                </div>
                
                <div className='mb-3'>
                    <h6 className='mb-1'><L en='Technical contact email'>Teknisen kontaktin sähköpostiosoite</L></h6>
                    <p className='mb-0'>{values['clientTechnicalEmail'] || '-'}</p>
                </div>
                
                <div className='mb-3'>
                    <h6 className='mb-1'><L en='Phone number'>Puhelinnumero</L></h6>
                    <p className='mb-0'>{values['clientTelephone'] || '-'}</p>
                </div>
                
                <div className='mb-3'>
                    <h6 className='mb-1'><L en='Mailing address'>Osoite</L></h6>
                    <p className='mb-0' style={{ whiteSpace: 'pre-wrap' }}>{values['clientAddress'] || '-'}</p>
                    <p className='mb-0'>{values['clientPostalCode'] || '-'} {values['clientCity'] || '-'}</p>
                </div>
                
                <div className='mb-3'>
                    <h6 className='mb-1'><L en='Invoicing'>Laskutus</L></h6>
                    <p className='mb-0'>{{ ebill: lang({ fi: 'E-laskuna', en: 'Online billing' }), email: lang({ fi: 'Sähköpostilla', en: 'E-mail invoice' }), post: lang({ fi: 'Paperilasku postitse', en: 'Paper invoice via mail' }) }[values['clientBillingPlan']] ?? '-'}</p>
                </div>
                
                <div className='mb-3'>
                    <h6 className='mb-1'><L en='Your reference number'>Viitteenne</L></h6>
                    <p className='mb-0'>{values['clientBillingRef'] || '-'}</p>
                </div>
                
                <div className='mb-3'>
                    <h6 className='mb-1'><L en='OVT number / Invoicing e-mail'>OVT-tunnus / Laskutuksen sähköposti</L></h6>
                    <p className='mb-0'>{values['clientOVT'] || '-'}</p>
                </div>
                
            </div>
        </Fragment>}
        
        <div className='d-flex justify-content-between mx-4 my-1'>
            
            {currentPart > 0
                ? <button className='btn btn-outline-dark'
                    onClick={e => {
                        e.preventDefault();
                        setCurrentPart(cp => cp-1);
                    }}
                    >
                    <RemixIcon icon='ri-arrow-left-line' className='me-1'/>
                    <L en='Previous'>Edellinen</L>
                </button>
                : <div></div>}
            
            {currentPart < parts.length - 1
                ? <button className='btn btn-primary'
                    onClick={e => {
                        
                        e.preventDefault();
                        
                        const errorFields: string[] = [];
                        for (const checkFn of Object.values(checksRef.current)) {
                            const result = checkFn()
                            if (result) {
                                errorFields.push(result);
                            }
                        }
                        
                        if (errorFields.length > 0) {
                            alert(`Tarkista seuraavat kentät / Check the following fields\n\n${errorFields.join('\n')}`);
                        } else {
                            setCurrentPart(cp => cp+1);
                        }
                        
                    }}
                    >
                    <RemixIcon icon='ri-arrow-right-line' className='me-1'/>
                    <L en='Next'>Seuraava</L>
                </button>
                : <button className='btn btn-success'
                    onClick={e => {
                        e.preventDefault();
                        postAPI(`/api/guest/saveClientDetails/${encodeURIComponent(editKey)}`, { values })
                            .then(resp => {
                                if (resp.error || !resp.success) {
                                    throw new Error(`${resp.error}`);
                                }
                                setSaved(true);
                            })
                            .catch(err => {
                                alert(`Tietojen lähetyksessä tapahtui virhe. Mikäli tämä toistuu, ota yhteys asiakaspalveluun.\n\n${err}`);
                            })
                    }}
                    >
                    <RemixIcon icon='ri-send-plane-line' className='me-1'/>
                    <L en='Submit details'>Lähetä tiedot</L>
                </button>}
            
        </div>
        
    </ChecksContext.Provider>
    
}

export function ClientVerifyForm(props: { editKey?: string }) {
    
    const { editKey } = props;
    
    const { error, result, isLoading } = useQuery<{key: string}>(`/api/guest/getVerificationSiteKey`);
    
    function onCaptchaChange(val: string | null) {
        if (!val) {
            return;
        }
        postAPI(`/api/guest/verify`, { captcha: val })
            .then(resp => {
                if (resp.isVerified) {
                    window.location.reload();
                    return;
                }
                alert(`Robottitarkistus epäonnistui. Yritä uudelleen.`);
                window.location.reload();
            })
            .catch(err => {
                alert(`${err}`);
                window.location.reload();
            })
    }
    
    if (isLoading) {
        return <LoadingSpinner/>;
    }
    
    if (error || !result) {
        return <GuestError>
            {error}
        </GuestError>
    }
    
    return <Fragment>
        
        <h4 className='text-center p-4'>Varmistetaan, että olet ihminen...</h4>
        
        <div className='text-center p-4 pt-0'>
            <ReCAPTCHA
                style={{ textAlign: 'center', display: 'inline-block' }}
                sitekey={result.key}
                onChange={onCaptchaChange}
                />
        </div>
        
    </Fragment>
    
}


