import { GlobalState } from '.';
import { useState } from 'preact/hooks';
import { Outlet, Route, Routes, useParams } from 'react-router';

const LisuDashboard = lazy(() => import('./dashboard').then(module => module.LisuDashboard));
const LisuClient = lazy(() => import('./subroutes/clients').then(module => module.LisuClient));
const LisuClientWrapper = lazy(() => import('./subroutes/clients').then(module => module.LisuClientWrapper));
const LisuClients = lazy(() => import('./subroutes/clients').then(module => module.LisuClients));
const LisuLicenseWrapper = lazy(() => import('./subroutes/licenses').then(module => module.LisuLicenseWrapper));
const LisuLicenses = lazy(() => import('./subroutes/licenses').then(module => module.LisuLicenses));
const LisuLogin = lazy(() => import('./login').then(module => module.LisuLogin));

const LisuQuotes = lazy(() => import('./subroutes/quotes').then(module => module.LisuQuotes));
const LisuQuoteWrapper = lazy(() => import('./subroutes/quotes').then(module => module.LisuQuoteWrapper));

const LisuOrders = lazy(() => import('./subroutes/orders').then(module => module.LisuOrders));
const LisuOrderWrapper = lazy(() => import('./subroutes/orders').then(module => module.LisuOrderWrapper));

import { BrowserRouter } from 'react-router-dom';
import { PureComponent, Suspense, lazy } from 'preact/compat';
import { h } from 'preact';
import { GuestEditClient } from './subroutes/guest/editClient';
import { LisuLangContext } from './utils/utils';
import { useEffect } from 'react';

(window as any).currentLang = 'fi';

export function LisuApp(props: { state: GlobalState }) {
    
    const urlParams = useParams();
    
    const [lang, setLang] = useState<string>('fi');
    useEffect(() => {
        (window as any).currentLang = lang;
    }, [lang]);
    
    return <Suspense fallback={
        <div className='p-4'>
            <div className='lisu-ui container-fluid m-4 p-4 text-center'>
                <div className='spinner-border text-primary' role='status'></div>
            </div>
        </div>
    }>
        
        <LisuLangContext.Provider value={{lang, setLang}}>
            
            <BrowserRouter>
                
                <Routes>
                    
                    <Route path='/app/*' element={<LisuDashboard state={props.state}/>}>
                        <Route path='admin' element={<Outlet/>}>
                            <Route path='clients' element={<LisuClients state={props.state}/>}>
                                <Route path=':clientId' element={<LisuClientWrapper state={props.state} key={urlParams.clientId ?? 'client?'}/>}/>
                            </Route>
                            <Route path='licenses' element={<LisuLicenses state={props.state}/>}>
                                <Route path=':orderId' element={<LisuLicenseWrapper state={props.state} key={urlParams.orderId ?? 'license?'}/>}/>
                            </Route>
                            <Route path='quotes' element={<LisuQuotes state={props.state}/>}>
                                <Route path=':quoteId' element={<LisuQuoteWrapper state={props.state} key={urlParams.quoteId ?? 'quote?'}/>}/>
                            </Route>
                            <Route path='orders' element={<LisuOrders state={props.state}/>}>
                                <Route path=':transactionId' element={<LisuOrderWrapper state={props.state} key={urlParams.quoteId ?? 'order?'}/>}/>
                            </Route>
                        </Route>
                    </Route>
                    
                    <Route path='/guest/editClientDetails/:editKey' element={<GuestEditClient/>}/>
                    
                    <Route path='/' element={<LisuLogin state={props.state}/>}/>
                    
                    <Route path='*' element={<div className='container m-4'>
                        <div className='alert alert-danger lisu-full-page-error'>
                            <h1>404</h1>
                            <p className='lead mb-0'>Koitin kyllä ettiä.</p>
                            <p className='mb-0'>
                                <a href='/app/' className='text-decoration-none'>&larr; Etusivulle</a>
                            </p>
                        </div>
                    </div>}/>
                    
                </Routes>
                
            </BrowserRouter>
            
        </LisuLangContext.Provider>
        
    </Suspense>;
    
}

